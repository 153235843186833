import React, { useState } from "react";
import logo from "../assets/logo.gif";
import styled from "styled-components";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import LinearProgress from "@mui/material/LinearProgress";
import Alert from "@mui/material/Alert";
import Snackbar from "@mui/material/Snackbar";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";

import { CustomRoomTable } from "./CustomRoomTable";
import { CreateRoomForm } from "./CreateRoomForm";
import { useAppSelector } from "../hooks";
import { useWeb3React } from '@web3-react/core'

import phaserGame from "../PhaserGame";
import Bootstrap from "../scenes/Bootstrap";

import Modal from "@mui/material/Modal";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import { borderRadius } from "@mui/system";
import Welcome from "../assets/tutorial/welcome.png";
import GettingStarted1 from "../assets/tutorial/getting-started-1.png";
import GettingStarted2 from "../assets/tutorial/getting-started-2.png";
import GettingStarted3 from "../assets/tutorial/getting-started-3.png";
import GettingStarted4 from "../assets/tutorial/getting-started-4.png";
import GettingStarted5 from "../assets/tutorial/tokentrader.png";
import GettingStarted6 from "../assets/tutorial/specimendealer.png";
import GettingStarted7 from "../assets/tutorial/microbiologis.png";
import { autocompleteClasses } from "@mui/material";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import ConnectWalletButton from "../dex-components/ConnectWalletButton";

const Backdrop = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  flex-direction: column;
  gap: 60px;
  align-items: center;
`;

const Wrapper = styled.div`
  background: #222639;
  border-radius: 16px;
  padding: 36px 60px;
  box-shadow: 0px 0px 5px #0000006f;
`;

const CustomRoomWrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 20px;
  align-items: center;
  justify-content: center;

  .tip {
    font-size: 18px;
  }
`;

const BackButtonWrapper = styled.div`
  position: absolute;
  top: 0;
  left: 0;
`;

const Title = styled.h1`
  font-size: 24px;
  color: #eee;
  text-align: center;
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  margin: 20px 0;
  align-items: center;
  justify-content: center;

  img {
    border-radius: 8px;
    height: 120px;
    object-fit: cover;
  }
`;

const ProgressBarWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  h3 {
    color: #33ac96;
  }
`;

const ProgressBar = styled(LinearProgress)`
  width: 360px;
`;

const modalReadGuide = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "40%",
  height: "60%",
  background: "#222639",
  borderRadius: "16px",
  boxShadow: "0px 0px 5px #0000006f",
  p: 4,
  overflow: "scroll",
};

const Bottom = styled.div`
  width: 100%;
  position: absolute;
  bottom: 0;
  display: flex;
  padding-right: 5em;
  padding-bottom: 2em;
`;

const SubTitle = styled.h3`
  width: 100%;
  font-size: 16px;
  color: #eee;
  text-align: center;
`;

const Description = styled.div`
  font-size: 16px;
  color: #c2c2c2;
  text-align: justify;
  img {
    max-width: 100%;
    height: auto;
    borderradius: 16;
    margintop: "2.5em";
  }
`;

// const modalStyle1 = styled.div`{
//     position: "absolute",
//     top: "10%",
//     left: "10%",
//     overflow: "scroll",
//     height: "100%",
//     display: "block",
//   }`;

const steps = [
  {
    section: 0,
  },
  {
    section: 1,
  },
  {
    section: 2,
  },
  {
    section: 3,
  },
  {
    section: 4,
  },
  {
    section: 5,
  },
  {
    section: 6,
  },
  {
    section: 7,
  },
  {
    section: 8,
  },
  {
    section: 9,
  },
  {
    section: 10,
  },
  {
    section: 11,
  },
];

export default function RoomSelectionDialog() {
  const { account } = useWeb3React()

  const [showCustomRoom, setShowCustomRoom] = useState(false);
  const [showCreateRoomForm, setShowCreateRoomForm] = useState(false);
  const [showSnackbar, setShowSnackbar] = useState(false);
  const lobbyJoined = useAppSelector((state) => state.room.lobbyJoined);
  const [open, setOpen] = React.useState(false);

  const [activeStep, setActiveStep] = React.useState(0);
  const [skipped, setSkipped] = React.useState(new Set<number>());

  const handleConnect = () => {
    if (lobbyJoined) {
      const bootstrap = phaserGame.scene.keys.bootstrap as Bootstrap;
      bootstrap.network
        .joinOrCreatePublic()
        .then(() => bootstrap.launchGame())
        .catch((error) => console.error(error));
    } else {
      setShowSnackbar(true);
    }
  };

  const handleOpenReadGuide = () => setOpen(true);
  const handleCloseReadGuide = () => setOpen(false);

  const isStepOptional = (step: number) => {
    return step === 1;
  };

  const isStepSkipped = (step: number) => {
    return skipped.has(step);
  };

  const handleNext = () => {
    let newSkipped = skipped;
    if (isStepSkipped(activeStep)) {
      newSkipped = new Set(newSkipped.values());
      newSkipped.delete(activeStep);
    }

    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    setSkipped(newSkipped);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleSkip = () => {
    if (!isStepOptional(activeStep)) {
      // You probably want to guard against something like this,
      // it should never occur unless someone's actively trying to break something.
      throw new Error("You can't skip a step that isn't optional.");
    }

    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    setSkipped((prevSkipped) => {
      const newSkipped = new Set(prevSkipped.values());
      newSkipped.add(activeStep);
      return newSkipped;
    });
  };

  const handleFinish = () => {
    setActiveStep(0);
    handleCloseReadGuide();
  };

  const getStepContent = (step: number, steps: any) => {
    switch (step) {
      case 0:
        return (
          <Grid container spacing={2}>
            <Grid item xs={4}>
              <Description>
                <img
                  src={Welcome}
                  alt={"alt"}
                  style={{ borderRadius: 16, marginTop: "2.5em" }}
                />
              </Description>
            </Grid>
            <Grid item xs={8}>
              <SubTitle></SubTitle>
              <Description>
                Welcome to the SUPAVerse, recruits! We’re glad that you can join
                us here on Mars. Let’s get you acquainted with the SUPA HQ!
                <br /> <br />
                To access the features in the SUPA HQ, you will first be
                required to have access to a <b>browser</b> with a{" "}
                <b>MetaMask</b> wallet extension installed as well as some{" "}
                <b>FTM</b> tokens in it. Click next and we’ll walk through this
                process together!
              </Description>
            </Grid>
          </Grid>
        );
      case 1:
        return (
          <Grid container spacing={8}>
            <Grid item xs={4}>
              <Description>
                <img
                  src={GettingStarted1}
                  alt={"alt"}
                  style={{ borderRadius: 12, marginTop: "2.5em" }}
                />
              </Description>
            </Grid>
            <Grid item xs={8}>
              <SubTitle>Getting Started</SubTitle>
              <Description>
                The first step is to have one of these browsers installed on
                your computer. We’d recommend <b>Google Chrome</b> or{" "}
                <b>Brave</b>. Once that’s done, we can proceed to the next step!
              </Description>
            </Grid>
            <Grid item xs={4}>
              <Description>
                <img
                  src={GettingStarted2}
                  alt={"alt"}
                  style={{ borderRadius: 10, marginTop: "2.5em" }}
                />
              </Description>
            </Grid>
            <Grid item xs={8}>
              <SubTitle></SubTitle>
              <Description>
                Before you can begin exploring the SUPA HQ, you will need a
                browser extension called <b>Metamask wallet</b>. You can click{" "}
                <a href="https://metamask.io/" target="_blank" rel="noreferrer">
                  here
                </a>{" "}
                to download Metamask from their website. <br />
                Click the blue download button and, on the following page,
                select your browser and download Metamask. Follow any prompts to
                enable the extension and accept security permissions. Once
                that’s done, click next and we’ll move to the next step.
              </Description>
            </Grid>
          </Grid>
        );
      case 2:
        return (
          <Grid container spacing={8}>
            <Grid item xs={4}>
              <Description>
                <img
                  src={GettingStarted2}
                  alt={"alt"}
                  style={{ borderRadius: 10, marginTop: "2.5em" }}
                />
              </Description>
            </Grid>
            <Grid item xs={8}>
              <SubTitle style={{ paddingTop: 5 }}></SubTitle>
              <Description>
                Excellent! Now that you have the extension installed, let’s
                configure this for the SUPAVerse! <br />
                <br /> This process may take a while, so I’ll bring you through
                this step by step. Remember, your wallet information is{" "}
                <b>extremely important and very sensitive</b>. If you lose your
                seed phrase, no one can recover it for you, which means that
                your account, along with your tokens and equipment, will be lost
                in space. <br />
                <br />
                Take your time to create a strong password and to securely store
                your recovery information. <br />
                <br />
                Now you can proceed and click <b>Get Started</b>. If you have an
                account with Metamask before, you can select Import Wallet to
                import your existing wallet. If you’ve never had Metamask
                before, fret not. Just follow the steps to create a wallet. Once
                that’s done, click next and we’ll move on to the next part!
              </Description>
            </Grid>
          </Grid>
        );
      case 3:
        return (
          <Grid container spacing={8}>
            <Grid item xs={4}>
              <Description>
                <img
                  src={GettingStarted3}
                  alt={"alt"}
                  style={{ borderRadius: 10, marginTop: "2.5em" }}
                />
              </Description>
            </Grid>
            <Grid item xs={8}>
              <SubTitle></SubTitle>
              <Description>
                You’re doing great! Remember that you should always keep your
                login information and seed phrase safe. Let’s proceed to the
                final part to set up Metamask so that you can start using it in
                the SUPAVerse! <br />
                <br /> Simply copy and paste the settings directly from Fantom
                Foundation’s documentation page{" "}
                <a
                  href="https://docs.fantom.foundation/tutorials/set-up-metamask"
                  target="_blank"
                  rel="noreferrer"
                >
                  here
                </a>
                . Start this process by opening the extension and clicking at
                the top where it says Ethereum Mainnet. From the dropdown menu,
                choose Custom RPC.
              </Description>
            </Grid>
          </Grid>
        );
      case 4:
        return (
          <Grid container spacing={8}>
            <Grid item xs={4}>
              <Description>
                <img
                  src={GettingStarted4}
                  alt={"alt"}
                  style={{ borderRadius: 10, marginTop: "2.5em" }}
                />
              </Description>
            </Grid>
            <Grid item xs={8}>
              <SubTitle></SubTitle>
              <Description>
                All you need to do now is copy and paste the below information:
                <br />
                <br />
                <b>Network Name:</b> Fantom Opera <br /> <b>RPC Url: </b>
                https://rpc.ftm.tools/ <br /> <b>ChainID:</b> 250 <br />
                <b>Symbol: </b>
                FTM Block
                <br />
                <b>Explorer URL:</b> https://ftmscan.com/
              </Description>
            </Grid>
          </Grid>
        );
      case 5:
        return (
          <Grid container spacing={8}>
            <Grid item xs={12}>
              <SubTitle>SUPA TOKENS</SubTitle>
              <Description>
                Before you begin exploring the SUPAVerse, you’ll need your
                wallet to create a character in-game as well as some FTM tokens.
                SUPA Foundation is hosted on the Fantom Opera blockchain, so
                you’ll need these tokens for gas fees (transaction fees charged
                when trading tokens) and to convert into SUPA (which is the main
                currency of the SUPAVerse). <br />
                <br />
                Gas fees on the Fantom Opera network are very low, so even a
                single FTM token is enough to last you for quite awhile;
                however, you’ll probably want more tokens to purchase SUPA and
                other tokens in-game. <br />
                <br />
                You may purchase SUPA on these exchanges: Spookyswap, Spiritswap
                and Hotbit. <br />
                <br />
                Note that if your vendor asks for a wallet address to deposit
                the tokens, you can get this address from Metamask. If you’ve
                just created the wallet, the default would be Account 1 and you
                can copy the wallet address by clicking on it. Remember to check
                that you are on the Fantom Mainnet before you proceed with any
                transactions. <br />
                <br />
                If you’ve purchased your SUPA tokens on Spookyswap or
                Spiritswap, the tokens are probably already in your Metamask
                wallet, but if you purchased it through a centralized exchange
                like Hotbit, you will need to manually transfer the tokens to
                your Metamask wallet. <br />
                <br />
                If you haven’t done this before and are worried about making a
                mistake, you can transfer a small amount to make sure it works
                before transferring the full balance over. Even though I’ve been
                here for quite awhile, I still do this as a precautionary
                measure. No need to rush, I’ll be around here when you are done
                transferring your tokens.
              </Description>
            </Grid>
          </Grid>
        );
      case 6:
        return (
          <Grid container spacing={8}>
            <Grid item xs={12}>
              <SubTitle>Congratulations!</SubTitle>
              <Description>
                Excellent! Now that your Metamask is installed and your SUPA
                tokens are in your Metamask wallet, I shall be able to grant you
                access to the SUPA HQ to explore! <br />
                <br />
                The SUPA HQ is currently in a disarray from the sabotage that
                has just happened. But don’t worry, we are in the midst of
                fixing it up. Follow our story on
                <a
                  href="https://supa.foundation/discord"
                  target="_blank"
                  rel="noreferrer"
                >
                  {" "}
                  Discord
                </a>{" "}
                to find out what happened. <br />
                <br />
                Meanwhile, we will need all the help we can get to get the SUPA
                HQ back up to speed. Let’s go, recruit!
              </Description>
            </Grid>
          </Grid>
        );
      case 7:
        return (
          <Grid container spacing={8}>
            <Grid item xs={12}>
              <SubTitle>Starting Location</SubTitle>
              <Description>
                Upon entering the SUPAVerse, you’ll be brought to our SUPA HQ.
                The first room you’ll notice at our SUPA HQ is the SUPA Lounge.
                This is the biggest room here in our SUPA HQ and it’s divided
                into several sub sections. <br />
                <br />I know recovering from COVID-21 has put some strain on
                your body. Try moving around by pressing the ⬅️⬆️⬇️➡️keys. You
                can interact with other recruits and some of our residents by
                pressing "R" when you are near them. <br />
                <br /> Some areas of the SUPA HQ are currently restricted due to
                the recent unfortunate series of events that happened. But with
                the help of our recruits, we will bring this place back to its
                former glory in no time! <br />
                <br />
                Now, let me tell you about our residents here at the SUPA HQ.
              </Description>
            </Grid>
          </Grid>
        );
      case 8:
        return (
          <Grid container spacing={8}>
            <Grid item xs={4}>
              <Description>
                <img
                  src={GettingStarted5}
                  alt={"alt"}
                  style={{ borderRadius: 10, marginTop: "2.5em" }}
                />
              </Description>
            </Grid>
            <Grid item xs={8}>
              <SubTitle>Token Trader (DEX)</SubTitle>
              <Description>
                The Marketplace is a sub section located in our SUPA Lounge.
                Here, you will find resident traders and merchants. It is the
                one stop solution to all your purchasing needs! <br />
                <br /> At the moment, the only available trader is our Token
                Trader. He will enable you to trade your existing tokens for
                SUPA tokens! You can also trade your SUPA tokens for other
                tokens if you wish. Think of him as a Decentralized Exchange.
              </Description>
            </Grid>
          </Grid>
        );
      case 9:
        return (
          <Grid container spacing={8}>
            <Grid item xs={4}>
              <Description>
                <img
                  src={GettingStarted6}
                  alt={"alt"}
                  style={{ borderRadius: 10, marginTop: "2.5em" }}
                />
              </Description>
            </Grid>
            <Grid item xs={8}>
              <SubTitle>Specimen Dealer (LP Creation)</SubTitle>
              <Description>
                This brings us to our next resident here at the SUPA Lounge, our
                Specimen Dealer. Apparently, he lurks around corners and rumors
                have it that he offers Specimens to those who can find him. The
                specimens he offers will allow you to create a Liquidity Pair.
                Once created, you may deposit them to our resident
                Microbiologist to progress our research to combat the pandemic!
                And of course earn some rewards in the process!
              </Description>
            </Grid>
          </Grid>
        );
      case 10:
        return (
          <Grid container spacing={8}>
            <Grid item xs={4}>
              <Description>
                <img
                  src={GettingStarted7}
                  alt={"alt"}
                  style={{ borderRadius: 10, marginTop: "2.5em" }}
                />
              </Description>
            </Grid>
            <Grid item xs={8}>
              <SubTitle>Microbiologist (LP staking)</SubTitle>
              <Description>
              You will find our resident Microbiologist in the lab. She is ever ready to receive your specimens which is essential towards our fight against the pandemic! Once you deposit your specimens to our Microbiologist, she will begin her research. Allowing her more time to perform her research will yield you more rewards. Think of this as LP Staking. Once the research is completed, you will be able to obtain the sweet rewards from your contributions!
               <br />
                <br />
                Research takes time and you can check the schedule{" "}
                <a
                  href="https://supafoundation.gitbook.io/supa-foundation/supaverse/supa-hq/the-lab/microbiologist-staking-yield-farming/research"
                  target="_blank"
                  rel="noreferrer"
                >
                  here
                </a>
                . Be careful not to disturb the research process too early. This will disrupt the research process and will result in a penalty as illustrated 
                {" "}
                <a
                  href="https://supafoundation.gitbook.io/supa-foundation/supaverse/supa-hq/the-lab/microbiologist-lp-staking/specimen-staking-and-withdrawal-fees"
                  target="_blank"
                  rel="noreferrer"
                >
                  here
                </a>.
                <br />
                <br />
                After all, saving humanity cannot be done overnight
              </Description>
            </Grid>
          </Grid>
        );
      case 11:
        return (
          <Grid container spacing={8}>
            <Grid item xs={12}>
              <SubTitle></SubTitle>
              <Description>
                Currently, that’s all we have going on in the SUPA HQ. We have a
                conference room that we plan to use for gatherings and possibly
                AMAs. Once we get some things in order, we will definitely need
                your help to reconstruct parts of the SUPA HQ. If you can’t find
                the other recruits in the SUPA HQ, join our{" "}
                <a
                  href="https://supa.foundation/discord"
                  target="_blank"
                  rel="noreferrer"
                >
                  Discord
                </a>{" "}
                and{" "}
                <a
                  href="https://t.me/supafoundation"
                  target="_blank"
                  rel="noreferrer"
                >
                  Telegram
                </a>{" "}
                to speak with them! <br />
                <br />
                Explore the SUPA HQ and connect with our recruits! Together, we
                will save humanity from the pandemic!
              </Description>
            </Grid>
          </Grid>
        );

      default:
        return "Unknown step";
    }
  };

  return (
    <>
      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        open={showSnackbar}
        autoHideDuration={3000}
        onClose={() => {
          setShowSnackbar(false);
        }}
      >
        <Alert
          severity="error"
          variant="outlined"
          // overwrites the dark theme on render
          style={{ background: "#fdeded", color: "#7d4747" }}
        >
          Trying to connect to server, please try again!
        </Alert>
      </Snackbar>
      <Backdrop>
        <Wrapper>
          {showCreateRoomForm ? (
            <CustomRoomWrapper>
              <Title>Create Custom Room</Title>
              <BackButtonWrapper>
                <IconButton onClick={() => setShowCreateRoomForm(false)}>
                  <ArrowBackIcon />
                </IconButton>
              </BackButtonWrapper>
              <CreateRoomForm />
            </CustomRoomWrapper>
          ) : showCustomRoom ? (
            <CustomRoomWrapper>
              <Title>
                Custom Rooms
                <Tooltip
                  title="We update the results in realtime, no refresh needed!"
                  placement="top"
                >
                  <IconButton>
                    <HelpOutlineIcon className="tip" />
                  </IconButton>
                </Tooltip>
              </Title>
              <BackButtonWrapper>
                <IconButton onClick={() => setShowCustomRoom(false)}>
                  <ArrowBackIcon />
                </IconButton>
              </BackButtonWrapper>
              <CustomRoomTable />
              <Button
                variant="contained"
                color="secondary"
                onClick={() => setShowCreateRoomForm(true)}
              >
                Create new room
              </Button>
            </CustomRoomWrapper>
          ) : (
            <>
              <Title>Welcome to SUPAVerse</Title>
              <Content>
                <img src={logo} alt="logo" />
                {account && ( <> <Button
                  variant="contained"
                  color="secondary"
                  onClick={handleConnect}
                  fullWidth
                >
                  Play
                </Button></>)} 
                {!account && ( <><ConnectWalletButton scale="sm" /></> )}
                <Button
                      variant="outlined"
                      color="secondary"
                      onClick={handleOpenReadGuide}
                      fullWidth
                    >
                      Tutorial
                    </Button>
              </Content>
            </>
          )}
        </Wrapper>
        {!lobbyJoined && (
          <ProgressBarWrapper>
            <h3> Connecting to server...</h3>
            <ProgressBar color="secondary" />
          </ProgressBarWrapper>
        )}
      </Backdrop>

      <Dialog
        open={open}
        onClose={handleCloseReadGuide}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle></DialogTitle>
        <DialogContent>
          <React.Fragment>
            <Box sx={{ flexGrow: 1 }}>{getStepContent(activeStep, steps)}</Box>
          </React.Fragment>
        </DialogContent>
        <DialogActions>
          <Button
            variant="outlined"
            color="secondary"
            disabled={activeStep === 0}
            onClick={handleBack}
            sx={{ mr: 1 }}
          >
            Back
          </Button>
          <Box sx={{ flex: "1 1 auto" }} />
          {activeStep === steps.length - 1 ? (
            <Button
              onClick={handleFinish}
              variant="contained"
              color="secondary"
            >
              {"Finish"}
            </Button>
          ) : (
            <Button onClick={handleNext} variant="contained" color="secondary">
              {"Next"}
            </Button>
          )}
        </DialogActions>
      </Dialog>
    </>
  );
}
