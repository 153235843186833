import { ethers } from 'ethers'
import { simpleRpcProvider } from '../dex-utils/providers'
import tokens from '../config/constants/tokens'

// Addresses
import {


  getMasterChefAddress,

  getMasterScientistAddress,

  

  getMulticallAddress,

} from '../dex-utils/addressHelpers'

// ABI

import bep20Abi from '../config/abi/erc20.json'
import lpTokenAbi from '../config/abi/lpToken.json'
import cakeAbi from '../config/abi/cake.json'
import masterChef from '../config/abi/masterchef.json'
import MultiCallAbi from '../config/abi/Multicall.json'
import masterScientist from '../config/abi/masterscientist.json'
import vestingAbi from '../config/abi/vesting.json'


const getContract = (abi: any, address: string, signer?: ethers.Signer | ethers.providers.Provider) => {
  const signerOrProvider = signer ?? simpleRpcProvider
  return new ethers.Contract(address, abi, signerOrProvider)
}

export const getBep20Contract = (address: string, signer?: ethers.Signer | ethers.providers.Provider) => {
  return getContract(bep20Abi, address, signer)
}
export const getVestingContract = (address: string, signer?: ethers.Signer | ethers.providers.Provider) => {
  return getContract(vestingAbi, address, signer)
}


export const getLpContract = (address: string, signer?: ethers.Signer | ethers.providers.Provider) => {
  return getContract(lpTokenAbi, address, signer)
}
export const getCakeContract = (signer?: ethers.Signer | ethers.providers.Provider) => {
  return getContract(cakeAbi, tokens.cake.address, signer)
}

export const getMasterchefContract = (signer?: ethers.Signer | ethers.providers.Provider) => {
  return getContract(masterChef, getMasterChefAddress(), signer)
}
export const getMasterscientistContract = (signer?: ethers.Signer | ethers.providers.Provider) => {
  return getContract(masterScientist, getMasterScientistAddress(), signer)
}

export const getMulticallContract = (signer?: ethers.Signer | ethers.providers.Provider) => {
  return getContract(MultiCallAbi, getMulticallAddress(), signer)
}
