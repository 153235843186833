import React, { useState } from "react";
import styled from "styled-components";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import Avatar from "@mui/material/Avatar";
import Alert from "@mui/material/Alert";
import AlertTitle from "@mui/material/AlertTitle";
import ArrowRightIcon from "@mui/icons-material/ArrowRight";

import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore, { Navigation } from "swiper";
import "swiper/swiper.min.css";
import "swiper/components/navigation/navigation.min.css";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Typography from "@mui/material/Typography";

import Male from "../assets/male_login.png";
import Female from "../assets/female_login.png";

// head male
import headMale1 from "../assets/custom/1head/head_1_male.png";
import headMale2 from "../assets/custom/1head/head_2_male.png";
import headMale3 from "../assets/custom/1head/head_3_male.png";

// body male
import bodyMale1 from "../assets/custom/2body/body_1_male.png";
import bodyMale2 from "../assets/custom/2body/body_2_male.png";
import bodyMale3 from "../assets/custom/2body/body_3_male.png";

// legs male
import legsMale1 from "../assets/custom/3legs/legs_1_male.png";
import legsMale2 from "../assets/custom/3legs/legs_2_male.png";
import legsMale3 from "../assets/custom/3legs/legs_3_male.png";

// head female
import headFemale1 from "../assets/custom/1head/head_1_female.png";
import headFemale2 from "../assets/custom/1head/head_2_female.png";
import headFemale3 from "../assets/custom/1head/head_3_female.png";

// body female
import bodyFemale1 from "../assets/custom/2body/body_1_male.png";
import bodyFemale2 from "../assets/custom/2body/body_2_male.png";
import bodyFemale3 from "../assets/custom/2body/body_3_male.png";

// legs female
import legsFemale1 from "../assets/custom/3legs/legs_1_male.png";
import legsFemale2 from "../assets/custom/3legs/legs_2_male.png";
import legsFemale3 from "../assets/custom/3legs/legs_3_male.png";

import { useAppSelector, useAppDispatch } from "../hooks";
import {
  setLoggedIn,
  setTextureName,
  setPlayerX,
  setPlayerY,
  setNamePlayer,
} from "../state/UserStore";
import { getAvatarString, getColorByString } from "../util";

import phaserGame from "../PhaserGame";
import Game from "../scenes/Game";

SwiperCore.use([Navigation]);

const Wrapper = styled.form`
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: #222639;
  border-radius: 16px;
  padding: 36px 60px;
  box-shadow: 0px 0px 5px #0000006f;
`;

const Title = styled.p`
  margin: 5px;
  font-size: 20px;
  color: #c2c2c2;
  text-align: center;
`;

const RoomName = styled.div`
  max-width: 500px;
  max-height: 120px;
  overflow-wrap: anywhere;
  overflow-y: auto;
  display: flex;
  gap: 10px;
  justify-content: center;

  h3 {
    font-size: 24px;
    color: #eee;
  }
`;

const RoomDescription = styled.div`
  max-width: 500px;
  max-height: 150px;
  overflow-wrap: anywhere;
  overflow-y: auto;
  font-size: 16px;
  color: #c2c2c2;
  display: flex;
  justify-content: center;
`;

const SubTitle = styled.h3`
  width: 160px;
  font-size: 16px;
  color: #eee;
  text-align: center;
`;

const Content = styled.div`
  display: flex;
  margin: 36px 0;
`;

const Left = styled.div`
  margin-right: 48px;

  --swiper-navigation-size: 24px;

  .swiper-container {
    width: 100px;
    height: 60px;
    border-radius: 8px;
    overflow: hidden;
    margin-bottom: 2px;
  }

  .swiper-slide {
    width: 100px;
    height: 60px;
    background: #dbdbe0;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .swiper-slide img {
    display: block;
    width: 50px;
    height: 86px;
    object-fit: contain;
  }
`;

const Right = styled.div`
  width: 300px;
`;

const Bottom = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const Warning = styled.div`
  margin-top: 30px;
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 3px;
`;

const avatars = [
  { name: "male", img: Male },
  { name: "female", img: Female },
];

// shuffle the avatars array
for (let i = avatars.length - 1; i > 0; i--) {
  const j = Math.floor(Math.random() * (i + 1));
  [avatars[i], avatars[j]] = [avatars[j], avatars[i]];
}

// shuffle the avatarsMale array
const avatarsCustom = [
  { name: "1", img: headMale1, type: "head", gender: "male" },
  { name: "2", img: headMale2, type: "head", gender: "male" },
  { name: "3", img: headMale3, type: "head", gender: "male" },
  { name: "1", img: bodyMale1, type: "body", gender: "male" },
  { name: "2", img: bodyMale2, type: "body", gender: "male" },
  { name: "3", img: bodyMale3, type: "body", gender: "male" },
  { name: "1", img: legsMale1, type: "legs", gender: "male" },
  { name: "2", img: legsMale2, type: "legs", gender: "male" },
  { name: "3", img: legsMale3, type: "legs", gender: "male" },
  { name: "1", img: headFemale1, type: "head", gender: "female" },
  { name: "2", img: headFemale2, type: "head", gender: "female" },
  { name: "3", img: headFemale3, type: "head", gender: "female" },
  { name: "1", img: bodyFemale1, type: "body", gender: "female" },
  { name: "2", img: bodyFemale2, type: "body", gender: "female" },
  { name: "3", img: bodyFemale3, type: "body", gender: "female" },
  { name: "1", img: legsFemale1, type: "legs", gender: "female" },
  { name: "2", img: legsFemale2, type: "legs", gender: "female" },
  { name: "3", img: legsFemale3, type: "legs", gender: "female" },
];

export default function LoginDialog() {
  const [name, setName] = useState<string>("");
  const [avatarIndex, setAvatarIndex] = useState<number>(0);
  const [avatarHeadIndex, setAvatarHeadIndex] = useState<number>(0);
  const [avatarBodyIndex, setAvatarBodyIndex] = useState<number>(0);
  const [avatarLegsIndex, setAvatarLegsIndex] = useState<number>(0);
  const [nameFieldEmpty, setNameFieldEmpty] = useState<boolean>(false);
  const [valueGenderRadio, setValueGenderRadio] = useState<string>("male");

  const dispatch = useAppDispatch();
  const videoConnected = useAppSelector((state) => state.userGame.videoConnected);
  const roomJoined = useAppSelector((state) => state.room.roomJoined);
  const roomName = useAppSelector((state) => state.room.roomName);
  const roomDescription = useAppSelector((state) => state.room.roomDescription);
  const game = phaserGame.scene.keys.FirstLevel as Game;

  const handleRadioChange = (e: any) => {
    setValueGenderRadio(e.target.value);
  };

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    if (name === "") {
      setNameFieldEmpty(true);
    } else if (roomJoined) {
      const nameAvatar =
        avatarsCustom[avatarHeadIndex].name +
        avatarsCustom[avatarBodyIndex].name +
        avatarsCustom[avatarLegsIndex].name +
        valueGenderRadio;
      const avatar = avatarIndex ? avatars[avatarIndex].name : nameAvatar;
      console.log("Join! Name:", name, "Avatar:", avatar);
      game.registerKeys();
      game.myPlayer.setPlayerName(name);
      game.myPlayer.setPlayerTexture(nameAvatar);
      game.network.readyToConnect();
      dispatch(setLoggedIn(true));
      dispatch(setTextureName(nameAvatar));
      dispatch(setNamePlayer(name));
    }
  };

  return (
    <Wrapper onSubmit={handleSubmit}>
      <Title>Joining</Title>
      <RoomName>
        <Avatar style={{ background: getColorByString(roomName) }}>
          {getAvatarString(roomName)}
        </Avatar>
        <h3>{roomName}</h3>
      </RoomName>
      <RoomDescription>
        <ArrowRightIcon /> {roomDescription}
      </RoomDescription>
      <Content>
        <Left>
          <SubTitle>Select an avatar</SubTitle>
          {valueGenderRadio === "male" && (
            <>
              <Swiper
                navigation
                spaceBetween={0}
                slidesPerView={1}
                onSlideChange={(swiper) => {
                  setAvatarHeadIndex(swiper.activeIndex);
                }}
              >
                {avatarsCustom
                  .filter(function (item) {
                    return item.type === "head" && item.gender === "male";
                  })
                  .map((avatar) => (
                    <SwiperSlide key={avatar.name}>
                      <img src={avatar.img} alt={avatar.name} />
                    </SwiperSlide>
                  ))}
              </Swiper>
              <Swiper
                navigation
                spaceBetween={0}
                slidesPerView={1}
                onSlideChange={(swiper) => {
                  setAvatarBodyIndex(swiper.activeIndex);
                }}
              >
                {avatarsCustom
                  .filter(function (item) {
                    return item.type === "body" && item.gender === "male";
                  })
                  .map((avatar) => (
                    <SwiperSlide key={avatar.name}>
                      <img src={avatar.img} alt={avatar.name} />
                    </SwiperSlide>
                  ))}
              </Swiper>
              <Swiper
                navigation
                spaceBetween={0}
                slidesPerView={1}
                onSlideChange={(swiper) => {
                  setAvatarLegsIndex(swiper.activeIndex);
                }}
              >
                {avatarsCustom
                  .filter(function (item) {
                    return item.type === "legs" && item.gender === "male";
                  })
                  .map((avatar) => (
                    <SwiperSlide key={avatar.name}>
                      <img src={avatar.img} alt={avatar.name} />
                    </SwiperSlide>
                  ))}
              </Swiper>
            </>
          )}
          {valueGenderRadio === "female" && (
            <>
              <Swiper
                navigation
                spaceBetween={0}
                slidesPerView={1}
                onSlideChange={(swiper) => {
                  setAvatarHeadIndex(swiper.activeIndex);
                }}
              >
                {avatarsCustom
                  .filter(function (item) {
                    return item.type === "head" && item.gender === "female";
                  })
                  .map((avatar) => (
                    <SwiperSlide key={avatar.name}>
                      <img src={avatar.img} alt={avatar.name} />
                    </SwiperSlide>
                  ))}
              </Swiper>
              <Swiper
                navigation
                spaceBetween={0}
                slidesPerView={1}
                onSlideChange={(swiper) => {
                  setAvatarBodyIndex(swiper.activeIndex);
                }}
              >
                {avatarsCustom
                  .filter(function (item) {
                    return item.type === "body" && item.gender === "female";
                  })
                  .map((avatar) => (
                    <SwiperSlide key={avatar.name}>
                      <img src={avatar.img} alt={avatar.name} />
                    </SwiperSlide>
                  ))}
              </Swiper>
              <Swiper
                navigation
                spaceBetween={0}
                slidesPerView={1}
                onSlideChange={(swiper) => {
                  setAvatarLegsIndex(swiper.activeIndex);
                }}
              >
                {avatarsCustom
                  .filter(function (item) {
                    return item.type === "legs" && item.gender === "female";
                  })
                  .map((avatar) => (
                    <SwiperSlide key={avatar.name}>
                      <img src={avatar.img} alt={avatar.name} />
                    </SwiperSlide>
                  ))}
              </Swiper>
            </>
          )}
        </Left>
        <Right>
          <TextField
            autoFocus
            fullWidth
            label="Name"
            variant="outlined"
            color="secondary"
            error={nameFieldEmpty}
            helperText={nameFieldEmpty && "Name is required"}
            onInput={(e) => {
              setName((e.target as HTMLInputElement).value);
            }}
          />
          <RadioGroup
            aria-label="Gender"
            name="gender"
            value={valueGenderRadio}
            onChange={handleRadioChange}
            style={{ paddingTop: "0.5em" }}
          >
            <FormControlLabel
              value="male"
              control={<Radio />}
              label={<Typography style={{ color: "#eee" }}>Male</Typography>}
            />
            <FormControlLabel
              value="female"
              control={<Radio />}
              label={<Typography style={{ color: "#eee" }}>Female</Typography>}
            />
          </RadioGroup>
          {!videoConnected && (
            <Warning>
              <Alert variant="outlined" severity="warning">
                <AlertTitle>Notice</AlertTitle>
                No webcam/mic connected -{" "}
                <strong>connect one for best experience!</strong>
              </Alert>
              <Button
                variant="outlined"
                color="secondary"
                onClick={() => {
                  game.network.webRTC?.getUserMedia();
                }}
              >
                Enable audio/video conferencing
              </Button>
            </Warning>
          )}

          {videoConnected && (
            <Warning>
              <Alert variant="outlined">Webcam connected!</Alert>
            </Warning>
          )}
        </Right>
      </Content>
      <Bottom>
        <Button
          variant="contained"
          color="secondary"
          size="large"
          type="submit"
          fullWidth
        >
          Join
        </Button>
      </Bottom>
    </Wrapper>
  );
}
