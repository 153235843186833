import React from "react";
import styled from "styled-components";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import { Redirect, Route, Switch } from "react-router-dom";

import { useAppSelector, useAppDispatch } from "../hooks";
import { closeSpecimenDealerDialog } from "../state/SpecimenDealerStore";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import Pool from "../views/Pool";
import AddLiquidity from "../views/AddLiquidity";
import PoolFinder from "../views/PoolFinder";
import {
  RedirectDuplicateTokenIds,
  RedirectOldAddLiquidityPathStructure,
  RedirectToAddLiquidity,
} from "../views/AddLiquidity/redirects";
import RedirectOldRemoveLiquidityPathStructure from "../views/RemoveLiquidity/redirects";
import RemoveLiquidity from "../views/RemoveLiquidity";
import Box from "@mui/material/Box";
import ClickAwayListener from "@mui/material/ClickAwayListener";

const Backdrop = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  overflow: hidden;
  align-items: center;
  justify-content: center;
`;
const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  background: #222639;
  border-radius: 16px;
  padding: 16px;
  color: #eee;
  position: relative;
  display: flex;
  flex-direction: column;

  .close {
    position: absolute;
    top: 16px;
    right: 16px;
  }
`;

const SpecimenDealerWrapper = styled.div`
  flex: 1;
  border-radius: 25px;
  overflow: hidden;
  margin-right: 50px;
  iframe {
    width: 100%;
    height: 100%;
  }
`;

const Description = styled.div`
  font-size: 16px;
  color: #c2c2c2;
  text-align: justify;
  img {
    max-width: 100%;
    height: auto;
    borderradius: 16;
    margintop: "2.5em";
  }
`;

const SwapParent = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-radius: 25px;
 
  .close {
    background: #222639;
    color: #eee;
    z-index: 2;
    bottom: 4px;
  }

  .close:hover {
    background: rgba(34, 38, 57, 0.8);
  }
`;
const SwapChild = styled.div`

overflow: scroll;

`;

export default function SpecimenDealerDialog() {
  const dispatch = useAppDispatch();
  const [open, setOpen] = React.useState(true);
  const [openChild, setOpenChild] = React.useState(false);

  const handleOpen = () => {};

  const handleClose = () => {
    setOpen(false);
    dispatch(closeSpecimenDealerDialog());
  };

  const handleOpenChild = () => {
    setOpenChild(true);
    setOpen(false);
  };

  const handleCloseChild = () => {
    setOpenChild(false);
    dispatch(closeSpecimenDealerDialog());
  };

  return (
    <>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        PaperProps={{
          elevation: 0,
          sx: {},
          style: {
            backgroundColor: "#222639",
          },
        }}
      >
        <DialogTitle></DialogTitle>
        <DialogContent>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Description>
                This is a fresh batch right out of the oven! Would you like to
                have a look?
              </Description>
            </Grid>
          </Grid>
        </DialogContent>
        <Switch>
          <Route path="/*">
            <Redirect to="/" />
          </Route>
        </Switch>
        <DialogActions>
          <Button
            variant="outlined"
            color="secondary"
            onClick={handleClose}
            fullWidth
          >
            No
          </Button>
          <Button
            variant="contained"
            color="secondary"
            onClick={handleOpenChild}
            fullWidth
          >
            Yes
          </Button>
        </DialogActions>
      </Dialog>

      {openChild && (
        <SwapParent>
                    <SwapChild>
            
          <IconButton
            onClick={handleCloseChild}
            color="primary"
            aria-label="close"
            component="span"
            className="close"
          >
            <CloseIcon />
          </IconButton>

          <Switch>
            <Route exact path="/">
              <Pool />
            </Route>
            <Route exact strict path="/find" component={PoolFinder} />
            <Route
              exact
              strict
              path="/create"
              component={RedirectToAddLiquidity}
            />
            <Route exact path="/add" component={AddLiquidity} />
            <Route
              exact
              path="/add/:currencyIdA"
              component={RedirectOldAddLiquidityPathStructure}
            />
            <Route
              exact
              path="/add/:currencyIdA/:currencyIdB"
              component={RedirectDuplicateTokenIds}
            />
            <Route exact path="/create" component={AddLiquidity} />
            <Route
              exact
              path="/create/:currencyIdA"
              component={RedirectOldAddLiquidityPathStructure}
            />
            <Route
              exact
              path="/create/:currencyIdA/:currencyIdB"
              component={RedirectDuplicateTokenIds}
            />
            <Route
              exact
              strict
              path="/remove/:tokens"
              component={RedirectOldRemoveLiquidityPathStructure}
            />
            <Route
              exact
              strict
              path="/remove/:currencyIdA/:currencyIdB"
              component={RemoveLiquidity}
            />
            <Route exact strict path="/liquidity" component={Pool} />
            <Route path="/pool">
              <Redirect to="/liquidity" />
            </Route>
          </Switch>

       
          </SwapChild>
        </SwapParent>
      )}

      {/* <Dialog
        open={openChild}
        onClose={handleCloseChild}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        PaperProps={{
          elevation: 0,
          sx: {},
          style: {
            backgroundColor: "transparent",
          },
        }}
      >
        <DialogContent></DialogContent>
      </Dialog> */}
    </>
  );
}
