// @ts-nocheck
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { sanitizeId } from "../util";
import { BackgroundMode } from "../../../types/BackgroundMode";
import phaserGame from "../PhaserGame";
import Bootstrap from "../scenes/Bootstrap";
import OtherPlayer from "../characters/OtherPlayer";
import ActionPanel from "../views/Farms/components/FarmTable/Actions/ActionPanel";
import { MAPS } from "../constants/maps";

export function getInitialBackgroundMode() {
  const currentHour = new Date().getHours();
  return currentHour > 6 && currentHour <= 18
    ? BackgroundMode.DAY
    : BackgroundMode.NIGHT;
}

export const userSlice = createSlice({
  name: "userGame",
  initialState: {
    backgroundMode: getInitialBackgroundMode(),
    sessionId: "",
    videoConnected: false,
    backgroundMusic: false,
    loggedIn: false,
    textureName: "",
    playerX: 0,
    playerY: 0,
    namePlayer: "",
    locationz:1,
    playerNameMap: new Map<string, string>(),
    otherPlayerStore: new Array(),
    notNew:false,
    playerPresence: new Map(),
    reset:false,
    currentScene:new Phaser.Scene(MAPS.firstLevel.key),
    physics:"" as any,
    thisPhysics:new Array(),
    scenes:new Array()
  },
  reducers: {

    setPlayerPresence: (state, action:PayloadAction<{}>)=>{
      state.playerPresence.set(action.payload[0],action.payload[1])
    },
    toggleBackgroundMode: (state) => {
      const newMode =
        state.backgroundMode === BackgroundMode.DAY
          ? BackgroundMode.NIGHT
          : BackgroundMode.DAY;
      state.backgroundMode = newMode;
      const bootstrap = phaserGame.scene.keys.bootstrap as Bootstrap;
      bootstrap.changeBackgroundMode(newMode);
    },
    setPhysics:(state,action:PayloadAction<(any)>)=>{
      state.physics=action.payload
    },
    setThisPhysics:(state,action:PayloadAction<(any)>)=>{
      state.thisPhysics.push(action.payload) 
    },
    setScenes:(state,action:PayloadAction<(any)>)=>{
      state.scenes.push(action.payload) 
    },
    toogleBackgroundMusicOn: (state) => {
      phaserGame.sound.add("backgroundMusic", {
        volume: 0.2,
        loop: true,
      });
      phaserGame.sound.pauseOnBlur = false;
      const currentlyPlaying = phaserGame.sound.get("backgroundMusic");
      if (!state.backgroundMusic && currentlyPlaying) {
        state.backgroundMusic = true;
        currentlyPlaying.play();
      }
    },
    setCurrentScene:(state,action:PayloadAction<(any)>)=>{
      state.currentScene=action.payload
    },
    setLocationz:(state, action:PayloadAction<number>)=>{
      state.locationz=action.payload;
    },
    toogleBackgroundMusicOff: (state) => {
      const currentlyPlaying = phaserGame.sound.get("backgroundMusic");
      state.backgroundMusic = false;
      currentlyPlaying.stop();
    },
    setSessionId: (state, action: PayloadAction<string>) => {
      state.sessionId = action.payload;
    },
    addOtherPlayer: (state, action: PayloadAction<{}>) => {
  
    state.otherPlayerStore.push(action.payload) ;
    state.otherPlayerStore = Array.from(new Set( state.otherPlayerStore.map(a => a.id)))
    .map(id => {
      return  state.otherPlayerStore.find(a => a.id === id)
    })
   
    
    },
    removeOtherPlayer: (state, action: PayloadAction<{}>) => {
      state.otherPlayerStore = state.otherPlayerStore .filter(function( obj ) {
        return obj.id !== action.payload.id;
      });
    },
    updateOtherPlayerScene:(state, action: PayloadAction<{}>)=>{
      if(action.payload[0]=='x'){
        for(let i=0;i<state.otherPlayerStore.length;i++){
          if(state.otherPlayerStore[i].id===action.payload[1]){
            state.otherPlayerStore[i].x=action.payload[2]
          }
        }
      }else if(action.payload[0]=='y'){
        for(let i=0;i<state.otherPlayerStore.length;i++){
          if(state.otherPlayerStore[i].id===action.payload[1]){
            state.otherPlayerStore[i].y=action.payload[2]
          }
        }
      }else if(action.payload[0]=='locationz'){
        for(let i=0;i<state.otherPlayerStore.length;i++){
          if(state.otherPlayerStore[i].id===action.payload[1]){
            state.otherPlayerStore[i].locationz=action.payload[2]
          }
        }
      }
     // console.log(action.payload)
    },
    setVideoConnected: (state, action: PayloadAction<boolean>) => {
      state.videoConnected = action.payload;
    },
    setNotNew: (state, action: PayloadAction<boolean>)=>{
      state.notNew=action.payload;
    },
    setReset:(state, action: PayloadAction<boolean>)=>{
      state.reset=action.payload;
    },
    setLoggedIn: (state, action: PayloadAction<boolean>) => {
      state.loggedIn = action.payload;
    },
    setTextureName: (state, action: PayloadAction<string>) => {
      state.textureName = action.payload;
    },
    setPlayerX: (state, action: PayloadAction<number>) => {
      state.playerX = action.payload;
    },
    setPlayerY: (state, action: PayloadAction<number>) => {
      state.playerY = action.payload;
    },
    setNamePlayer: (state, action: PayloadAction<string>) => {
      state.namePlayer = action.payload;
    },
    setPlayerNameMap: (
      state,
      action: PayloadAction<{ id: string; name: string }>
    ) => {
      state.playerNameMap.set(
        sanitizeId(action.payload.id),
        action.payload.name
      );
    },
    removePlayerNameMap: (state, action: PayloadAction<string>) => {
      state.playerNameMap.delete(sanitizeId(action.payload));
    },
  },
});

export const {
  toggleBackgroundMode,
  toogleBackgroundMusicOn,
  toogleBackgroundMusicOff,
  setSessionId,
  setVideoConnected,
  setLoggedIn,
  addOtherPlayer,
  setPlayerNameMap,
  removePlayerNameMap,
  setTextureName,
  setNamePlayer,
  setLocationz,
  setPlayerX,
  setPlayerY,
  removeOtherPlayer,
  setPlayerPresence,
  setNotNew,
  updateOtherPlayerScene,
  setReset,
  setPhysics,
  setCurrentScene,
  setThisPhysics,
  setScenes
} = userSlice.actions;

export default userSlice.reducer;
