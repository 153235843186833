import { enableMapSet } from "immer";
import { configureStore, getDefaultMiddleware } from '@reduxjs/toolkit'
import { save, load } from 'redux-localstorage-simple'
import cloneDeep from 'lodash/cloneDeep'
import { useDispatch } from 'react-redux'
import farmsReducer from './farms'

import blockReducer from './block'

import { updateVersion } from './global/actions'
import user, { initialState as userInitialState } from './user/reducer'
import transactions, { initialState as transactionsInitialState } from './transactions/reducer'
import swap from './swap/reducer'
import mint from './mint/reducer'
import lists, { initialState as listsInitialState } from './lists/reducer'
import burn from './burn/reducer'
import multicall from './multicall/reducer'



import userReducer from "./UserStore";
import computerReducer from "./ComputerStore";
import whiteboardReducer from "./WhiteboardStore";
import chatReducer from "./ChatStore";
import roomReducer from "./RoomStore";
import microBiologistReducer from "./MicrobiologistStore";
import specimenDealerReducer from "./SpecimenDealerStore";
import tokenTraderReducer from "./TokenTraderStore";
const PERSISTED_KEYS: string[] = ['user', 'transactions', 'lists', 'profile']

enableMapSet();

const store = configureStore({
  devTools: process.env.NODE_ENV !== 'production',
  reducer: {
    userGame: userReducer,
    computer: computerReducer,
    whiteboard: whiteboardReducer,
    chat: chatReducer,
    room: roomReducer,
    microBiologist: microBiologistReducer,
    specimenDealer: specimenDealerReducer,
    tokenTrader: tokenTraderReducer,
    block: blockReducer,
    farms: farmsReducer,
    user,
    transactions,
    swap,
    mint,
    burn,
    multicall,
    lists,
  },
  // Temporary disable serialize check for redux as we store MediaStream in ComputerStore.
  // https://stackoverflow.com/a/63244831
  middleware: [...getDefaultMiddleware({ thunk: true ,serializableCheck: false,}), save({ states: PERSISTED_KEYS })],
  preloadedState: load({
    states: PERSISTED_KEYS,
    preloadedState: {
      user: cloneDeep(userInitialState),
      transactions: cloneDeep(transactionsInitialState),
      lists: cloneDeep(listsInitialState),
    },
  }),

});
store.dispatch(updateVersion())

// Infer the `RootState` and `AppDispatch` types from the store itself
export type AppState = ReturnType<typeof store.getState>;
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch;
export const useAppDispatch = () => useDispatch()


export default store;
