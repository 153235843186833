import 'regenerator-runtime/runtime'
import React from 'react'
import ReactDOM from 'react-dom'
import  Providers  from './Providers'
import { ThemeProvider } from '@mui/material/styles'

import './index.scss'
import './PhaserGame'
import muiTheme from './MuiTheme'
import App from './App'
import reportWebVitals from './reportWebVitals'
import store from './state'
import ListsUpdater from './state/lists/updater'
import MulticallUpdater from './state/multicall/updater'
import TransactionUpdater from './state/transactions/updater'
function Updaters() {
  return (
    <>
      <ListsUpdater />
      <TransactionUpdater />
      <MulticallUpdater />
    </>
  )
}
ReactDOM.render(
  <React.StrictMode>
    <Providers >
      <ThemeProvider theme={muiTheme}>
      <Updaters />

        <App />
      </ThemeProvider>
    </Providers>
  </React.StrictMode>,
  document.getElementById('root')
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
