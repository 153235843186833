import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IChatMessage } from "../../../types/IOfficeState";
import { SCENES } from "../constants/scenes";
import phaserGame from "../PhaserGame";
import FirstLevel from "../scenes/FirstLevel";
import Game from "../scenes/Game";
import SecondLevel from "../scenes/SecondLevel";
import { MAPS } from "../constants/maps";

export enum MessageType {
  PLAYER_JOINED,
  PLAYER_LEFT,
  REGULAR_MESSAGE,
}

export const chatSlice = createSlice({
  name: "chat",
  initialState: {
    chatMessages: new Array<{
      messageType: MessageType;
      chatMessage: IChatMessage;
    }>(),
    focused: false,
    showChat: true,
    showChatButton: true,
    levelName: new Phaser.Scene(MAPS.firstLevel.key),

  },
  reducers: {
    setLevel: (state, action: PayloadAction<any>) => {
      state.levelName = action.payload;
      
      // Register keys for level
      var game=action.payload;
     
      
    },
    pushChatMessage: (state, action: PayloadAction<IChatMessage>) => {
      state.chatMessages.push({
        messageType: MessageType.REGULAR_MESSAGE,
        chatMessage: action.payload,
      });
    },
    pushPlayerJoinedMessage: (state, action: PayloadAction<string>) => {
      state.chatMessages.push({
        messageType: MessageType.PLAYER_JOINED,
        chatMessage: {
          createdAt: new Date().getTime(),
          author: action.payload,
          content: "joined the lobby",
        } as IChatMessage,
      });
    },
    pushPlayerLeftMessage: (state, action: PayloadAction<string>) => {
      state.chatMessages.push({
        messageType: MessageType.PLAYER_LEFT,
        chatMessage: {
          createdAt: new Date().getTime(),
          author: action.payload,
          content: "left the lobby",
        } as IChatMessage,
      });
    },
    setFocused: (state, action: PayloadAction<boolean>) => {
      var game=state.levelName;
     state.focused=action.payload
      
    },
    setShowChat: (state, action: PayloadAction<boolean>) => {
      state.showChat = action.payload;
    },
    setShowChatButton: (state, action: PayloadAction<boolean>) => {
      state.showChatButton = action.payload;
    },
  },
});

export const {
  pushChatMessage,
  setLevel,
  pushPlayerJoinedMessage,
  pushPlayerLeftMessage,
  setFocused,
  setShowChat,
  setShowChatButton
} = chatSlice.actions;

export default chatSlice.reducer;
