import { useCallback } from 'react'
import { harvestFarm } from '../../../dex-utils/calls'
import { useMasterscientist } from '../../../hooks/useContract'

const useHarvestFarm = (farmPid: number) => {
  const masterScientistContract = useMasterscientist()

  const handleHarvest = useCallback(async () => {
    await harvestFarm(masterScientistContract, farmPid)
  }, [farmPid, masterScientistContract])

  return { onReward: handleHarvest }
}

export default useHarvestFarm
