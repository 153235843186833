import React from "react";
import styled from "styled-components";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";

import { useAppSelector, useAppDispatch } from "../hooks";
import { closeMicrobiologistDialog } from "../state/MicrobiologistStore";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import Farms from "../views/Farms/Farms";

const Backdrop = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  overflow: hidden;
  padding: 16px 180px 16px 16px;
`;
const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  background: #222639;
  border-radius: 16px;
  padding: 16px;
  color: #eee;
  position: relative;
  display: flex;
  flex-direction: column;

  .close {
    position: absolute;
    top: 16px;
    right: 16px;
  }
`;

const MicroBiologistWrapper = styled.div`
  flex: 1;
  border-radius: 25px;
  overflow: hidden;
  margin-right: 50px;
  iframe {
    width: 100%;
    height: 100%;
  }
`;

const Description = styled.div`
  font-size: 16px;
  color: #c2c2c2;
  text-align: justify;
  img {
    max-width: 100%;
    height: auto;
    borderradius: 16;
    margintop: "2.5em";
  }
`;

const SwapParent = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: center;
  background-color: rgba(255, 255, 255, 0.2);
`;

const InnerComponent = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: center;
`;

export default function MicroBiologistDialog() {
  const dispatch = useAppDispatch();
  const [open, setOpen] = React.useState(true);
  const [openChild, setOpenChild] = React.useState(false);

  const handleOpen = () => {};

  const handleClose = () => {
    setOpen(false);
    dispatch(closeMicrobiologistDialog());
  };

  const handleOpenChild = () => {
    setOpenChild(true);
    setOpen(false);
  };

  const handleCloseChild = () => {
    setOpenChild(false);
    dispatch(closeMicrobiologistDialog());
  };
  const SwapChild = styled.div`

  overflow: scroll;
  width:100%;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: center;
  
  `;
  return (
    <>
      {openChild && (
        <SwapParent onClick={handleCloseChild}>
          <SwapChild>
          {/* <IconButton
            onClick={handleCloseChild}
            color="primary"
            aria-label="close"
            component="span"
          >
            <CloseIcon />
          </IconButton> */}
          <div style={{ width: "80%", height: "100%" }} className="children">
            <div onClick={(e) => e.stopPropagation()}>
              <Farms />
            </div>
          </div>
          </SwapChild>
        </SwapParent>
      )}
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        PaperProps={{
          elevation: 0,
          sx: {},
          style: {
            backgroundColor: "#222639",
          },
        }}
      >
        <DialogTitle></DialogTitle>
        <DialogContent>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Description>
                We always welcome new specimens for our research. But most
                importantly, we don’t welcome disruptions to our research.{" "}
                <b>
                  (IMPORTANT: THERE WILL BE A FEE IMPOSED FOR EARLY
                  WITHDRAWALS.)
                </b>{" "}
                Would you like to proceed?
              </Description>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button
            variant="outlined"
            color="secondary"
            onClick={handleClose}
            fullWidth
          >
            No
          </Button>
          <Button
            variant="contained"
            color="secondary"
            onClick={handleOpenChild}
            fullWidth
          >
            Yes
          </Button>
        </DialogActions>
      </Dialog>
      {/* <Dialog
        open={openChild}
        onClose={handleCloseChild}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        maxWidth="xl"
        PaperProps={{
          elevation: 0,
          sx: {
          },
          style: {
            backgroundColor: '#222639',
        
          },
        }}
      >
        <DialogContent >
        <Farms />
        </DialogContent>
      </Dialog> */}
    </>
  );
}
