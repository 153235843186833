import React, { useCallback } from 'react'
import { useWeb3React } from '@web3-react/core'
import styled from 'styled-components'
import BigNumber from 'bignumber.js'
import { Button, Flex, Heading, IconButton, AddIcon, MinusIcon, useModal } from '@pancakeswap/uikit'
import { useLocation } from 'react-router-dom'
import Balance from '../../../../dex-components/Balance'
import { useTranslation } from '../../../../contexts/Localization'
import { useAppDispatch } from '../../../../state'
import { fetchFarmUserDataAsync } from '../../../../state/farms'
import { useFarmUser, useLpTokenPrice } from '../../../../state/farms/hooks'
import { getBalanceAmount, getBalanceNumber } from '../../../../dex-utils/formatBalance'
import DepositModal from '../DepositModal'
import WithdrawModal from '../WithdrawModal'
import useUnstakeFarms from '../../hooks/useUnstakeFarms'
import useStakeFarms from '../../hooks/useStakeFarms'

interface FarmCardActionsProps {
  stakedBalance?: BigNumber
  tokenBalance?: BigNumber
  tokenName?: string
  pid?: number
  multiplier?: string
  apr?: number
  displayApr?: string
  addLiquidityUrl?: string
  cakePrice?: BigNumber
  lpLabel?: string
}

const IconButtonWrapper = styled.div`
  display: flex;
  svg {
    width: 20px;
  }
`

const StakeAction: React.FC<FarmCardActionsProps> = ({
  stakedBalance,
  tokenBalance,
  tokenName,
  pid,
  multiplier,
  apr,
  displayApr,
  addLiquidityUrl,
  cakePrice,
  lpLabel,
}) => {
  const { t } = useTranslation()
  const { onStake } = useStakeFarms(pid as any)
  const { onUnstake } = useUnstakeFarms(pid as any)
  const location = useLocation()
  const dispatch = useAppDispatch()
  const { account } = useWeb3React()
  const lpPrice = useLpTokenPrice(tokenName as any)
  const { userDelta,earnings } = useFarmUser(pid)

  const handleStake = async (amount: string) => {
    await onStake(amount)
    dispatch(fetchFarmUserDataAsync({ account, pids: [pid] } as any))
  }

  const handleUnstake = async (amount: string) => {
    await onUnstake(amount)
    dispatch(fetchFarmUserDataAsync({ account, pids: [pid] } as any))
  }
  let LPfee=0.25
  
  if(userDelta!.isGreaterThanOrEqualTo(0) && userDelta!.isLessThanOrEqualTo(3600)){
    LPfee=0.25
  }
  else if(userDelta!.isGreaterThanOrEqualTo(3601) && userDelta!.isLessThanOrEqualTo(86400)){
    LPfee=0.08
  }else if(userDelta!.isGreaterThanOrEqualTo(86401) && userDelta!.isLessThanOrEqualTo(259200)){
    LPfee=0.04
  }else if(userDelta!.isGreaterThanOrEqualTo(259201) && userDelta!.isLessThanOrEqualTo(432000)){
    LPfee= 0.02
  }else if(userDelta!.isGreaterThanOrEqualTo(432001) && userDelta!.isLessThanOrEqualTo(1209600)){
    LPfee= 0.01
  }else if(userDelta!.isGreaterThanOrEqualTo(1209601) && userDelta!.isLessThanOrEqualTo(2419200)){
    LPfee= 0.005
  }else if(userDelta!.isGreaterThanOrEqualTo(2419201)){
    LPfee= 0.0005
  }else{
    LPfee=0.25
  }
  const displayBalance = useCallback(() => {
    const stakedBalanceBigNumber = getBalanceAmount(stakedBalance as any)
    if (stakedBalanceBigNumber.gt(0) && stakedBalanceBigNumber.lt(0.0000001)) {
      return '<0.0000001'
    }
    if (stakedBalanceBigNumber.gt(0)) {
      return stakedBalanceBigNumber.toFixed(8, BigNumber.ROUND_DOWN)
    }
    return stakedBalanceBigNumber.toFixed(3, BigNumber.ROUND_DOWN)
  }, [stakedBalance])

  const [onPresentDeposit] = useModal(
    <DepositModal
      max={tokenBalance as any}
      stakedBalance={stakedBalance as any}
      onConfirm={handleStake}
      tokenName={tokenName}
      multiplier={multiplier}
      lpPrice={lpPrice}
      lpLabel={lpLabel}
      apr={apr}
      displayApr={displayApr}
      addLiquidityUrl={addLiquidityUrl}
      cakePrice={cakePrice}
    />,
  )
  const [onPresentWithdraw] = useModal(
    <WithdrawModal max={stakedBalance as any} onConfirm={handleUnstake} tokenName={tokenName} LPFee={LPfee} earnings={earnings} />,
  )

  const renderStakingButtons = () => {
    return stakedBalance!.eq(0) ? (
      <Button
        onClick={onPresentDeposit}
        disabled={['history', 'archived'].some((item) => location.pathname.includes(item))}
      >
        {t('Stake LP')}
      </Button>
    ) : (
      <IconButtonWrapper>
        <IconButton variant="tertiary" onClick={onPresentWithdraw} mr="6px">
          <MinusIcon color="primary" width="14px" />
        </IconButton>
        <IconButton
          variant="tertiary"
          onClick={onPresentDeposit}
          disabled={['history', 'archived'].some((item) => location.pathname.includes(item))}
        >
          <AddIcon color="primary" width="14px" />
        </IconButton>
      </IconButtonWrapper>
    )
  }

  return (
    <Flex justifyContent="space-between" alignItems="center">
      <Flex flexDirection="column" alignItems="flex-start">
        <Heading color={stakedBalance!.eq(0) ? 'textDisabled' : 'text'}>{displayBalance()}</Heading>
        {stakedBalance!.gt(0) && lpPrice.gt(0) && (
          <Balance
            fontSize="12px"
            color="textSubtle"
            decimals={2}
            value={getBalanceNumber(lpPrice.times(stakedBalance as any))}
            unit=" USD"
            prefix="~"
          />
        )}
      </Flex>
      {renderStakingButtons()}
    </Flex>
  )
}

export default StakeAction
