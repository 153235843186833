import  React,{ useCallback, useMemo, useState } from 'react'
import {  Button, Heading, Skeleton, Text, useModal } from '@pancakeswap/uikit'
import BigNumber from 'bignumber.js'
import { useWeb3React } from '@web3-react/core'
import { FarmWithStakedValue } from '../../../../../views/Farms/components/FarmCard/FarmCard'
import Balance from '../../../../../dex-components/Balance'
import { BIG_ZERO } from '../../../../../dex-utils/bigNumber'
import { getBalanceAmount } from '../../../../../dex-utils/formatBalance'
import { useAppDispatch } from '../../../../../state'
import { fetchFarmUserDataAsync } from '../../../../../state/farms'
import { useFarmUser, usePriceCakeBusd } from '../../../../../state/farms/hooks'
import useToast from '../../../../../hooks/useToast'
import { useTranslation } from '../../../../../contexts/Localization'

import { AutoColumn } from '../../../../../dex-components/Layout/Column'
import { RowBetween, RowFixed } from '../../../../../dex-components/Layout/Row'
import { CurrencyLogo } from '../../../../../dex-components/Logo'
import { useDefaultTokenList } from '../../../../../state/lists/hooks'
import TransactionConfirmationModal, { ConfirmationModalContent } from '../../../../../dex-components/TransactionConfirmationModal'

import useHarvestFarm from '../../../hooks/useHarvestFarm'
import { ActionContainer, ActionTitles, ActionContent } from './styles'




interface HarvestActionProps extends FarmWithStakedValue {
  userDataReady: boolean

}

 const HarvestAction: React.FunctionComponent<HarvestActionProps>  =  ({ pid, userData, userDataReady }) => {
  const [attemptingTxn, setAttemptingTxn] = useState(false) // clicked confirm

  const { toastSuccess, toastError } = useToast()
  const earningsBigNumber = new BigNumber(userData!.earnings)
  const cakePrice = usePriceCakeBusd()
  let earnings = BIG_ZERO
  let earningsBusd = 0
  let displayBalance = userDataReady ? earnings.toLocaleString() : <Skeleton width={60} />

  
  // If user didn't connect wallet default balance will be 0
  if (!earningsBigNumber.isZero()) {
    earnings = getBalanceAmount(earningsBigNumber)
    earningsBusd = earnings.multipliedBy(cakePrice).toNumber()
    displayBalance = earnings.toFixed(3, BigNumber.ROUND_DOWN)
  }


  const { onReward } = useHarvestFarm(pid)
  const { t } = useTranslation()
  const dispatch = useAppDispatch()
  const { account } = useWeb3React()
  
 const lockPercentage=71;


  const supaToken= useDefaultTokenList()[250]['0x59D07a115fe3FFe5db3D52029D43Cc0ef5e9ba08'].token
 function modalHeader() {
  const lockedAmount= earnings.multipliedBy(lockPercentage).div(100).toFixed(3, BigNumber.ROUND_DOWN)
  const releaseAmount= earnings.minus(lockedAmount).toFixed(3, BigNumber.ROUND_DOWN)
    return (
      <AutoColumn gap="md">
          <Text small textAlign="left" pt="12px">
          Current vesting rate is {lockPercentage}% of your total rewards. Please see <a href="https://supafoundation.gitbook.io/supa-foundation/supaverse/supa-hq/the-lab/microbiologist-lp-staking/research-schedule-locking" target="_blank" rel="noreferrer">LINK</a> for more info.
         
        </Text>
        <RowFixed>
        <Text fontSize="24px">Immediate unlock amount:</Text>

        </RowFixed>
        <RowBetween align="flex-end">
      
          <Text fontSize="24px">{releaseAmount}</Text>
          <RowFixed gap="4px">
            <CurrencyLogo currency={supaToken} size="24px" />
            <Text fontSize="24px" ml="10px">
              {supaToken?.symbol}
            </Text>
          </RowFixed>
        </RowBetween>
        <RowFixed>
        <Text fontSize="24px">Vested amount (locked):</Text>
        </RowFixed>
        <RowBetween align="flex-end">
          <Text fontSize="24px">{lockedAmount}</Text>
          <RowFixed gap="4px">
            <CurrencyLogo currency={supaToken} size="24px" />
            <Text fontSize="24px" ml="10px">
              {supaToken?.symbol}
            </Text>
          </RowFixed>
        </RowBetween>

    
      </AutoColumn>
    )
  }
  function modalBottom() {

    return (
      <>
     
        <Button onClick={goHarvest}>
          {t('Confirm')}
        </Button>
      </>
    )
  }

const goHarvest=async()=>{

    
    try {
      setAttemptingTxn(true)

      await onReward()
      toastSuccess(
        `${t('Harvested')}!`,
        t('Your %symbol% earnings have been sent to your wallet and a proportion has been vested', { symbol: 'SUPA' }),
      )
    } catch (e) {
      toastError(
        t('Error'),
        t('Please try again. Confirm the transaction and make sure you are paying enough gas!'),
      )
      console.error(e)
    } 
    setAttemptingTxn(false)
    dispatch(fetchFarmUserDataAsync({ account, pids: [pid] }as any))
  }

const pendingText = `Harvesting SUPA tokens`
  const [displayModal] =useModal(
  
  < TransactionConfirmationModal
    title={t('Harvest SUPA')}
    attemptingTxn={attemptingTxn}
    hash=''
    content={() => <ConfirmationModalContent topContent={modalHeader} bottomContent={modalBottom} />}
    pendingText={pendingText}
  />
  
  ,
  true,
  true,
  'removeLiquidityModal')
  return (
    <ActionContainer>
      <ActionTitles>
        <Text bold textTransform="uppercase" color="secondary" fontSize="12px" pr="4px">
          SUPA
        </Text>
        <Text bold textTransform="uppercase" color="textSubtle" fontSize="12px">
          {t('Earned')}
        </Text>
      </ActionTitles>
      <ActionContent>
        <div>
          <Heading>{displayBalance}</Heading>
          {earningsBusd > 0 && (
            <Balance fontSize="12px" color="textSubtle" decimals={2} value={earningsBusd} unit=" USD" prefix="~" />
          )}
        </div>
        <Button
          disabled={earnings.eq(0) || attemptingTxn || !userDataReady}
          onClick={
            displayModal}
          ml="4px"
        >
          {attemptingTxn ? t('Harvesting') : t('Harvest')}
        </Button>
      </ActionContent>
    </ActionContainer>
  )
}

export default HarvestAction
