import { serializeTokens } from './tokens'
import { SerializedFarmConfig } from './types'

const serializedTokens = serializeTokens()

const farms: SerializedFarmConfig[] = [
  /**
   * These 3 farms (PID 0, 251, 252) should always be at the top of the file.
   */
  {
    pid: 0,
    lpSymbol: 'SUPA-WFTM',
    lpAddresses: {
      97: '0x9C21123D94b93361a29B2C2EFB3d5CD8B17e0A9e',
      250: '0xFC1f27a029566376C07AAfa05706f890905DD014',
    },
    token: serializedTokens.cake,
    quoteToken: serializedTokens.wbnb,
  },
  {
    pid: 1,
    lpSymbol: 'SUPA-ETH',
    lpAddresses: {
      97: '0x9C21123D94b93361a29B2C2EFB3d5CD8B17e0A9e',
      250: '0xca345267fC769aa52F4EF98463108015d35E10d7',
    },
    token: serializedTokens.eth,
    quoteToken: serializedTokens.cake,
  },
  {
    pid: 2,
    lpSymbol: 'SUPA-USDC',
    lpAddresses: {
      97: '0x9C21123D94b93361a29B2C2EFB3d5CD8B17e0A9e',
      250: '0x115E22b6b73e0e9B9DcD3122E7421E28BEBe9418',
    },
    token: serializedTokens.cake,
    quoteToken: serializedTokens.busd,
  },
  {
    pid: 3,
    lpSymbol: 'SUPA-BOO',
    lpAddresses: {
      97: '0x9C21123D94b93361a29B2C2EFB3d5CD8B17e0A9e',
      250: '0xf851154F8f5e994536465982608289e1b1AF80d5',
    },
    token: serializedTokens.boo,
    quoteToken: serializedTokens.cake,
  },
  {
    pid: 4,
    lpSymbol: 'SUPA-RING',
    lpAddresses: {
      97: '0x9C21123D94b93361a29B2C2EFB3d5CD8B17e0A9e',
      250: '0xA470b01336A9B879DE6A9F823845da0c851806c5',
    },
    token: serializedTokens.ring,
    quoteToken: serializedTokens.cake,
  },
  // {
  //   pid: 5,
  //   lpSymbol: 'SUPA-BEETS',
  //   lpAddresses: {
  //     97: '0x9C21123D94b93361a29B2C2EFB3d5CD8B17e0A9e',
  //     250: '0x358A13F51fB5B98aa7eAc0F8B9c07A7f34cd1a85',
  //   },
  //   token: serializedTokens.beets,
  //   quoteToken: serializedTokens.cake,
  // },

  {
    pid: 5,
    lpSymbol: 'SUPA-FANG',
    lpAddresses: {
      97: '0x9C21123D94b93361a29B2C2EFB3d5CD8B17e0A9e',
      250: '0xe939C9A55245ACFD856AE9edAb7695C0AbF03C8F',
    },
    token: serializedTokens.fang,
    quoteToken: serializedTokens.cake,
  },
  {
    pid: 6,
    lpSymbol: 'WFTM-USDC',
    lpAddresses: {
      97: '0x9C21123D94b93361a29B2C2EFB3d5CD8B17e0A9e',
      250: '0xD847E171e639787Eda77C1947E28f21e0E9B23c9',
    },
    token: serializedTokens.busd,
    quoteToken: serializedTokens.wbnb,
  },
 

  {
    pid: 7,
    lpSymbol: 'WFTM-ETH',
    lpAddresses: {
      97: '0x9C21123D94b93361a29B2C2EFB3d5CD8B17e0A9e',
      250: '0x553E0707062D7C777b41a597f60360C5Cc48A246',
    },
    token: serializedTokens.eth,
    quoteToken: serializedTokens.wbnb,
  },
 {
    pid: 8,
    lpSymbol: 'SUPA-BEETS',
    lpAddresses: {
      97: '0x9C21123D94b93361a29B2C2EFB3d5CD8B17e0A9e',
      250: '0xE7034D48c5BFc37C74dc02090eB813ACe076e72b',
    },
    token: serializedTokens.beets,
    quoteToken: serializedTokens.cake,
  },
  
 
]

export default farms
