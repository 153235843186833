import { useCallback } from 'react'
import { unstakeFarm } from '../../../dex-utils/calls'
import { useMasterscientist } from '../../../hooks/useContract'

const useUnstakeFarms = (pid: number) => {
  const masterScientistContract = useMasterscientist()

  const handleUnstake = useCallback(
    async (amount: string) => {
      await unstakeFarm(masterScientistContract, pid, amount)
    },
    [masterScientistContract, pid],
  )

  return { onUnstake: handleUnstake }
}

export default useUnstakeFarms
