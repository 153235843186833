import { ChainId, Token } from '@pancakeswap/sdk'
import { serializeToken } from '../../state/user/hooks/helpers'
import { SerializedToken } from './types'

const { MAINNET, TESTNET } = ChainId

interface TokenList {
  [symbol: string]: Token
}

interface SerializedTokenList {
  [symbol: string]: SerializedToken
}

export const mainnetTokens = {
  wbnb: new Token(
    MAINNET,
    '0x21be370D5312f44cB42ce377BC9b8a0cEF1A4C83',
    18,
    'WFTM',
    'Wrapped Fantom',
    'https://www.fantom.foundation',
  ),
  // bnb here points to the wbnb contract. Wherever the currency BNB is required, conditional checks for the symbol 'FTM' can be used
  bnb: new Token(MAINNET, '0x21be370D5312f44cB42ce377BC9b8a0cEF1A4C83', 18, 'Fantom', 'FTM', 'https://www.fantom.foundation'),
  cake: new Token(
    MAINNET,
    '0x59D07a115fe3FFe5db3D52029D43Cc0ef5e9ba08',
    18,
    'SUPA',
    'SUPA Foundation Token',
    'https://supa.foundation/',
  ),
  busd: new Token(
    MAINNET,
    '0x04068DA6C83AFCFA0e13ba15A6696662335D5B75',
    6,
    'USDC',
    'USD Coin',
    'https://www.centre.io/usdc',
  ),
  eth: new Token(
    MAINNET,
    '0x74b23882a30290451A17c44f4F05243b6b58C76d',
    18,
    'ETH',
    'PancakeSwap Token',
    'https://ethereum.org',
  ),
  ring: new Token(
    MAINNET,
    '0x582423C10c9e83387a96d00A69bA3D11ee47B7b5',
    18,
    'RING',
    'PancakeSwap Token',
    'https://onering.finance',
  ),
  fang: new Token(
    MAINNET,
    '0x49894fCC07233957c35462cfC3418Ef0CC26129f',
    18,
    'FANG',
    'Fang Token',
    'https://potluckprotocol.com/',
  ),
  boo: new Token(
    MAINNET,
    '0x841FAD6EAe12c286d1Fd18d1d525DFfA75C7EFFE',
    18,
    'BOO',
    'SpookySwap Finance',
    'https://spookyswap.finance/',
  ),
  beets: new Token(
    MAINNET,
    '0xF24Bcf4d1e507740041C9cFd2DddB29585aDCe1e',
    18,
    'BEETS',
    'BeethovenX',
    'https://beets.fi',
  ),
}

export const testnetTokens = {
  wbnb: new Token(
    TESTNET,
    '0x21be370D5312f44cB42ce377BC9b8a0cEF1A4C83',
    18,
    'WFTM',
    'Wrapped Fantom',
    'https://www.fantom.foundation',
  ),
  // bnb here points to the wbnb contract. Wherever the currency BNB is required, conditional checks for the symbol 'FTM' can be used
  bnb: new Token(MAINNET, '0x21be370D5312f44cB42ce377BC9b8a0cEF1A4C83', 18, 'Fantom', 'FTM', 'https://www.fantom.foundation'),
  cake: new Token(
   TESTNET,
    '0x59D07a115fe3FFe5db3D52029D43Cc0ef5e9ba08',
    18,
    'SUPA',
    'SUPA Foundation Token',
    'https://supa.foundation/',
  ),
  busd: new Token(
    TESTNET,
    '0x04068DA6C83AFCFA0e13ba15A6696662335D5B75',
    6,
    'USDC',
    'USD Coin',
    'https://www.centre.io/usdc',
  ),
 
}

const tokens = (): TokenList => {
  const chainId = process.env.REACT_APP_CHAIN_ID

  // If testnet - return list comprised of testnetTokens wherever they exist, and mainnetTokens where they don't
  if (parseInt(chainId as any, 10) === ChainId.TESTNET) {
    return Object.keys(mainnetTokens).reduce((accum, key) => {
      return { ...accum, [key]: testnetTokens[key] || mainnetTokens[key] }
    }, {})
  }

  return mainnetTokens
}

export const serializeTokens = (): SerializedTokenList => {
  const unserializedTokens = tokens()
  const serializedTokens = Object.keys(unserializedTokens).reduce((accum, key) => {
    return { ...accum, [key]: serializeToken(unserializedTokens[key]) }
  }, {})

  return serializedTokens
}
export default tokens()
