export default {
  masterChef: {
    97: '0xf39c314a6d98e7e6D56583E92ec229B51795058B',
    250: '0x23De8ECbb781846966E723Cd8196019DD6C41a70',
  },
  masterScientist: {
    97: '0xf39c314a6d98e7e6D56583E92ec229B51795058B',
    250: '0x23De8ECbb781846966E723Cd8196019DD6C41a70',
  },
  multiCall: {
    97: '0x8F3273Fb89B075b1645095ABaC6ed17B2d4Bc576',
    250: '0xBcF26fC82c422f00aa06076D506214aa9dd72b8B',
  },
  vesting: {
    97: '0x778b8659ea21a39c0fEC64755cA0434f5c96a2d3',
    250: '0x778b8659ea21a39c0fEC64755cA0434f5c96a2d3',
  }
  
 
}
